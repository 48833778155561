import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CustomTheme } from '~/components/MUI/MUITheme';

export const useStyles = makeStyles(({ mode, platform }: CustomTheme) =>
  createStyles({
    root: {
      color: {
        dark: {
          android: '#59c754',
          iOS: '#119fef',
        }[platform.name],
        white: {
          android: '#59c754',
          iOS: '#007aff',
        }[platform.name],
      }[mode],
    },
  }),
);
