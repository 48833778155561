export enum Platforms {
  Android = 'android',
  IOS = 'iOS',
}

export enum Colors {
  Grey = '#a8a8a8',

  AndroidWhite = '#ffffff',
  AndroidBlue = '#3c9ff0',
  AndroidGreen = '#59c754',
  AndroidGrey = '#d9d9d9',
  AndroidGreyDark = '#999999',
  IOSBlue = '#007aff',
  IOSGrey = '#eeeef0',
  IOSGreyDark = '#8e8d92',
}
