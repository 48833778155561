import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { LocalizedRoutes } from '~/containers/LocalizedRoutes';
import { ServicePage } from '~/containers/ServicePage';

export const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/service_page">
          <ServicePage />
        </Route>

        <Route path="/:langCode?">
          <LocalizedRoutes />
        </Route>
      </Switch>
    </Router>
  );
};
