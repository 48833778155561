import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '120px 0 48px',
      textAlign: 'center',
    },
  }),
);
