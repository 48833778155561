import React, { FC, useMemo } from 'react';

import { Platforms } from './constants';
import { PlatformContext } from './PlatformContext';
import { PlatformContextType } from './types';

export const PlatformProvider: FC = (props) => {
  const { children } = props;

  const platform = useMemo<PlatformContextType>(() => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    return {
      isAndroid: !isIOS,
      isIOS,
      name: isIOS ? Platforms.IOS : Platforms.Android,
    };
  }, []);

  return (
    <PlatformContext.Provider value={platform}>
      {children}
    </PlatformContext.Provider>
  );
};
