import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CustomTheme } from '~/components/MUI/MUITheme';

export const useStyles = makeStyles(({ platform: { isIOS } }: CustomTheme) =>
  createStyles({
    root: {
      margin: isIOS ? '29px 20px 0' : '24px 18px 0',
      padding: 0,
    },
  }),
);
