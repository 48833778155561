import { Locales } from './types';

export const DEFAULT_LOCALE = 'en';

export const locales: Locales = {
  be: {
    loadUI: () => import('./translations/ui.be.json'),
    loadCountries: () => import('./translations/countries.be.json'),
  },
  ca: {
    loadUI: () => import('./translations/ui.ca.json'),
    loadCountries: () => import('./translations/countries.ca.json'),
  },
  de: {
    loadUI: () => import('./translations/ui.de.json'),
    loadCountries: () => import('./translations/countries.de.json'),
  },
  en: {
    loadUI: () => import('./translations/ui.en.json'),
    loadCountries: () => import('./translations/countries.en.json'),
  },
  es: {
    loadUI: () => import('./translations/ui.es.json'),
    loadCountries: () => import('./translations/countries.es.json'),
  },
  fr: {
    loadUI: () => import('./translations/ui.fr.json'),
    loadCountries: () => import('./translations/countries.fr.json'),
  },
  id: {
    loadUI: () => import('./translations/ui.id.json'),
    loadCountries: () => import('./translations/countries.id.json'),
  },
  it: {
    loadUI: () => import('./translations/ui.it.json'),
    loadCountries: () => import('./translations/countries.it.json'),
  },
  ko: {
    loadUI: () => import('./translations/ui.ko.json'),
    loadCountries: () => import('./translations/countries.ko.json'),
  },
  ms: {
    loadUI: () => import('./translations/ui.ms.json'),
    loadCountries: () => import('./translations/countries.ms.json'),
  },
  nl: {
    loadUI: () => import('./translations/ui.nl.json'),
    loadCountries: () => import('./translations/countries.nl.json'),
  },
  pl: {
    loadUI: () => import('./translations/ui.pl.json'),
    loadCountries: () => import('./translations/countries.pl.json'),
  },
  'pt-br': {
    loadUI: () => import('./translations/ui.pt-br.json'),
    loadCountries: () => import('./translations/countries.pt-br.json'),
  },
  ru: {
    loadUI: () => import('./translations/ui.ru.json'),
    loadCountries: () => import('./translations/countries.ru.json'),
  },
  tr: {
    loadUI: () => import('./translations/ui.tr.json'),
    loadCountries: () => import('./translations/countries.tr.json'),
  },
  uk: {
    loadUI: () => import('./translations/ui.uk.json'),
    loadCountries: () => import('./translations/countries.uk.json'),
  },
  uz: {
    loadUI: () => import('./translations/ui.uz.json'),
    loadCountries: () => import('./translations/countries.uz.json'),
  },
};
