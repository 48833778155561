import * as React from 'react';

import type { BotAccount, BotId, Hash, Session, SessionId } from './types';

import { useApi } from '~/api';
import { Status } from '~/containers/SessionStatusPage/constants';

interface State {
  error: null | string;
  session: null | Session;
}

type UseSessionStatus = (params: {
  botAccount: BotAccount;
  botId: BotId;
  hash: Hash;
  sessionId: SessionId;
}) => State;

export const useSessionStatus: UseSessionStatus = (params) => {
  const { botAccount, botId, hash, sessionId } = params;

  const api = useApi();

  const [state, setState] = React.useState<State>({
    error: null,
    session: null,
  });

  const getStatus = React.useCallback(async () => {
    try {
      const nextSession = await api.getSessionStatus({
        botAccount,
        botId,
        hash,
        sessionId,
      });

      if ('description' in nextSession && nextSession.description) {
        setState((prevState) => ({
          ...prevState,
          error: nextSession.description,
        }));

        return;
      }

      if (
        !state.session ||
        ('status' in nextSession && nextSession.status !== state.session.status)
      ) {
        setState({
          error: null,
          session: nextSession as Session,
        });
      }

      if ('status' in nextSession && nextSession.status === Status.InProgress) {
        return getStatus();
      }
    } catch {
      setState((prevState) => ({
        ...prevState,
        error: 'Unknown error',
      }));
    }
  }, [api, botAccount, botId, hash, sessionId, state.session]);

  React.useEffect(() => {
    getStatus();
  }, []);

  return state;
};
