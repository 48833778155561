import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CustomTheme } from '~/components/MUI/MUITheme';

import { PageTitleProps } from './PageTitle';

type Props = Pick<PageTitleProps, 'marginBottom' | 'marginTop'>;

export const useStyles = makeStyles(
  ({ mode, platform: { isIOS } }: CustomTheme) =>
    createStyles({
      root: {
        margin: ({ marginTop, marginBottom }: Props) => {
          if (isIOS) {
            return `${marginTop * 8}px 5px ${marginBottom * 9}px`;
          }

          return `${marginTop * 8}px 18px ${marginBottom * 9}px`;
        },
        color: {
          dark: '#fff',
          white: '#222',
        }[mode],
        fontFamily: 'inherit',
        fontSize: isIOS ? '22px' : '20px',
        fontWeight: 'bold',
        lineHeight: isIOS ? '27px' : '24px',
      },
    }),
);
