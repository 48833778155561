import React, { ComponentType, FC } from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

export interface PageTitleProps {
  className?: string;
  component?: ComponentType<Omit<PageTitleProps, 'component'>> | string;
  marginTop?: number;
  marginBottom?: number;
}

export const PageTitle: FC<PageTitleProps> = (props) => {
  const {
    children,
    className,
    component: Component = 'h1',
    marginTop = 2,
    marginBottom = 3,
  } = props;
  const classes = useStyles({ marginTop, marginBottom });

  return (
    <Component className={clsx(classes.root, className)}>{children}</Component>
  );
};
