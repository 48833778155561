import React, { memo } from 'react';

import { PageTitle } from '~/components/PageTitle';
import { useTranslations } from '~/containers/LocalizedRoutes';

import {
  Description,
  ErrorIcon,
  PendingIcon,
  SuccessIcon,
  WaitingIcon,
} from './components';
import { Statuses } from './constants';

const titleProps = {
  marginBottom: 4 / 3,
  marginTop: 14 / 9,
};

interface MUIStatusProps {
  status: Statuses;
  statusTitle?: React.ReactNode;
  statusDescription?: React.ReactNode;
  statusButton?: JSX.Element;
}

export const MUIStatus: React.FC<MUIStatusProps> = memo((props) => {
  const {
    status,
    statusTitle = '',
    statusDescription = '',
    statusButton = null,
  } = props;
  const { i18n } = useTranslations();

  if (status === Statuses.Pending) {
    return (
      <>
        <PendingIcon />

        <PageTitle {...titleProps}>
          {statusTitle ?? i18n['title:loading']}
        </PageTitle>

        <Description>{statusDescription}</Description>

        {statusButton ? statusButton : null}
      </>
    );
  }

  if (status === Statuses.Waiting) {
    return (
      <>
        <WaitingIcon />

        <PageTitle {...titleProps}>
          {statusTitle ?? i18n['title:loading']}
        </PageTitle>

        <Description>{statusDescription}</Description>

        {statusButton ? statusButton : null}
      </>
    );
  }

  if (status === Statuses.Success) {
    return (
      <>
        <SuccessIcon />

        <PageTitle {...titleProps}>
          {statusTitle ?? i18n['title:success']}
        </PageTitle>

        <Description>{statusDescription}</Description>

        {statusButton ? statusButton : null}
      </>
    );
  }

  if (status === Statuses.Error) {
    return (
      <>
        <ErrorIcon />

        <PageTitle {...titleProps}>
          {statusTitle ?? i18n['title:error']}
        </PageTitle>

        <Description>{statusDescription}</Description>

        {statusButton ? statusButton : null}
      </>
    );
  }

  if (status === Statuses.Neutral) {
    return (
      <>
        {statusTitle && <PageTitle {...titleProps}>{statusTitle}</PageTitle>}

        <Description>{statusDescription}</Description>

        {statusButton ? statusButton : null}
      </>
    );
  }

  return <PendingIcon />;
});
