import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

interface StatusContainerProps {
  className?: string;
}

export const StatusContainer: React.FC<StatusContainerProps> = (props) => {
  const { children, className } = props;
  const classes = useStyles();

  return <div className={clsx(classes.root, className)}>{children}</div>;
};
