import React, { FC } from 'react';

import { useStyles } from './styles';

export const SuccessIcon: FC = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.root}
      width="66"
      height="66"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-440 -442)" fill="none">
        <circle fill="currentColor" cx="540" cy="542" r="100" />
        <path
          d="M583 520.5a7 7 0 010 10l-48.1 48a7 7 0 01-5.4 2 7 7 0 01-5.4-2L501.5 556a7 7 0 019.9-9.9l18.1 18.1 43.6-43.6a7 7 0 019.9 0z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
