import React, { useContext } from 'react';

import { TranslationsType } from '~/i18n/types';

interface TranslationsContextType {
  i18n?: TranslationsType;
  lang?: string;
}

export const TranslationsContext = React.createContext<TranslationsContextType>(
  {},
);

export function useTranslations(): TranslationsContextType {
  return useContext(TranslationsContext);
}
