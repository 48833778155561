import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CustomTheme } from '~/components/MUI/MUITheme';

export const useStyles = makeStyles(({ mode, platform }: CustomTheme) =>
  createStyles({
    root: {
      color: {
        dark: {
          android: '#ef676e',
          iOS: '#ff5f56',
        }[platform.name],
        white: {
          android: '#e36d6d',
          iOS: '#fe3b30',
        }[platform.name],
      }[mode],
    },
  }),
);
