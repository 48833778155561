import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

interface PageContent {
  className?: string;
}

export const PageContent: React.FC<PageContent> = (props) => {
  const { children, className } = props;
  const classes = useStyles();

  return <main className={clsx(classes.root, className)}>{children}</main>;
};
