import { RequestResult } from './types';

interface OptionsType {
  baseUrl?: string;
  headers?: { [key: string]: string };
}

const DEFAULT_BASE_OPTIONS: OptionsType = {
  baseUrl: process.env.API_DONATION_BOT_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

/**
 * Объединяет два объекта настроек запроса.
 *
 * @param {OptionsType} [baseOptions={}] - Базовый объект настроек.
 * @param {OptionsType} [options={}] - Применяемый объект настроек.
 * @returns {OptionsType} - Новый объект настроек.
 */
function mergeOptions(
  baseOptions: OptionsType = {},
  options: OptionsType = {},
): OptionsType {
  return {
    ...baseOptions,
    ...options,
    headers: {
      ...(baseOptions.headers || {}),
      ...(options.headers || {}),
    },
  };
}

type RequestType<R = undefined> = Promise<RequestResult<R>>;

type GetRequestType = <T = undefined>(
  path: string,
  options?: OptionsType,
) => RequestType<T>;

interface IPostOptionsType<D> extends OptionsType {
  data?: D;
}

type PostRequestType = <D = Record<string, unknown>, T = undefined>(
  path: string,
  options?: IPostOptionsType<D>,
) => RequestType<T>;

type CreatorRoutesType = (params: {
  get: GetRequestType;
  post: PostRequestType;
}) => unknown;

export class ApiService<
  Routes extends Record<string, (...params: unknown[]) => Promise<unknown>>,
> {
  readonly options: OptionsType;

  readonly routes: Routes;

  constructor(
    createRoutes: (params: {
      get: GetRequestType;
      post: PostRequestType;
    }) => Routes,
    options: OptionsType = {},
  ) {
    this.routes = createRoutes({
      get: this.get,
      post: this.post,
    });

    this.options = mergeOptions(DEFAULT_BASE_OPTIONS, options);
  }

  private async request(path, options) {
    const { baseUrl = '', ...restOptions } = mergeOptions(
      this.options,
      options,
    );

    const response = await fetch(`${baseUrl}${path}`, restOptions);
    const { status } = response;
    const parsedResponse = await response.json();
    const result = {
      response: parsedResponse,
      status,
    };

    if (status < 200 || status >= 300) {
      return Promise.reject(result);
    }

    return Promise.resolve(result);
  }

  get: GetRequestType = (path, options = {}) => {
    return this.request(path, {
      ...options,
      method: 'get',
    });
  };

  post: PostRequestType = (path, options = {}) => {
    const { data = {}, ...restOptions } = options;

    return this.request(path, {
      ...restOptions,
      body: JSON.stringify(data),
      method: 'post',
    });
  };
}
