import React, { FC } from 'react';

import { useStyles } from './styles';

export const ErrorIcon: FC = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.root}
      width="66"
      height="66"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-440 -592)">
        <circle fill="currentColor" cx="540" cy="692" r="100" />
        <path
          d="M576.4 656a7 7 0 010 10L550 692.5l26.5 26.5a7 7 0 01.2 9.7l-.2.2a7 7 0 01-9.9 0L540 702.4l-26.5 26.5a7 7 0 01-10-9.9l26.6-26.5-26.5-26.5a7 7 0 01-.2-9.7l.2-.2a7 7 0 019.9 0l26.5 26.5 26.5-26.5a7 7 0 0110 0z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
