import { useMemo } from 'react';

import { createRoutes } from './createRoutes';
import { Options } from './types';

export const useApi = ({ publicToken, uuid, sign }: Options = {}) => {
  return useMemo(
    () => createRoutes({ publicToken, uuid, sign }),
    [publicToken, sign, uuid],
  );
};
