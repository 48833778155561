import React, { memo, PropsWithChildren } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import { MUITheme } from '~/components/MUI/MUITheme';
import { PageContent } from '~/components/PageContent';
import { PlatformProvider } from '~/components/Platform';

type PageRootProps = PropsWithChildren<{
  classes?: {
    content?: string;
  };
}>;

export const PageRoot = memo<PageRootProps>((props) => {
  const { children, classes = {} } = props;

  return (
    <PlatformProvider>
      <MUITheme>
        <CssBaseline />

        <PageContent className={classes.content}>{children}</PageContent>
      </MUITheme>
    </PlatformProvider>
  );
});
