import { PlatformContextType } from './types';

export enum Platforms {
  Android = 'android',
  IOS = 'iOS',
}

export const DEFAULT_VALUES: PlatformContextType = {
  isAndroid: true,
  isIOS: false,
  name: Platforms.Android,
};
