import React, { FC } from 'react';

import { useStyles } from './styles';

export const PendingIcon: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <svg
        className={classes.icon}
        width="62"
        height="62"
        fill="none"
        viewBox="0 0 186 186"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 93A86 86 0 1093 7"
          stroke="currentColor"
          strokeWidth="14"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
