import React from 'react';

import { PageRoot } from '~/components/PageRoot';
import { PageTitle } from '~/components/PageTitle';

import { useStyles } from './styles';

export const ServicePage: React.FC = () => {
  const classes = useStyles();

  return (
    <PageRoot classes={classes}>
      <PageTitle>Oops! Internal error</PageTitle>

      <p>Bad things have happened.</p>
    </PageRoot>
  );
};
