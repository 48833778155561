import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CustomTheme } from '~/components/MUI/MUITheme';

export const useStyles = makeStyles(({ mode, platform }: CustomTheme) =>
  createStyles({
    root: {
      display: 'inline-block',
      width: '62px',
      position: 'relative',

      '&::after': {
        display: 'block',
        paddingTop: '100%',
        content: '""',
      },
    },

    icon: {
      position: 'absolute',
      top: 0,
      left: 0,
      color: {
        dark: {
          android: '#77c4fe',
          iOS: '#119fef',
        }[platform.name],
        white: {
          android: '#3c9ff0',
          iOS: '#007aff',
        }[platform.name],
      }[mode],
      animationDuration: '1s',
      animationIterationCount: 'infinite',
      animationName: '$rotation',
      animationTimingFunction: 'linear',
    },

    '@keyframes rotation': {
      from: {
        transform: 'rotateZ(0)',
      },
      to: {
        transform: 'rotateZ(360deg)',
      },
    },
  }),
);
