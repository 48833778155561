import React from 'react';

import { PageRoot } from '~/components/PageRoot';
import { PageTitle } from '~/components/PageTitle';
import { useTranslations } from '~/containers/LocalizedRoutes';

export const NotFoundPage: React.FC = () => {
  const { i18n } = useTranslations();

  return (
    <PageRoot>
      <PageTitle>{i18n['title:page_not_found']}</PageTitle>
    </PageRoot>
  );
};
