import React from 'react';

import { useStyles } from './styles';

export const WaitingIcon: React.FC = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.root}
      width="66"
      height="66"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="100" cy="100" r="100" fill="currentColor" />
      <path
        d="M60 55a6 6 0 016-6h68a6 6 0 016 6v17.78a20 20 0 01-9.33 16.91l-12.24 7.72a3.06 3.06 0 000 5.18l12.24 7.72a20 20 0 019.33 16.91V145a6 6 0 01-6 6H66a6 6 0 01-6-6v-17.78a20 20 0 019.33-16.91l12.24-7.72a3.06 3.06 0 000-5.18l-12.24-7.72A20 20 0 0160 72.78V55zm12 6v11.78a8 8 0 003.73 6.76l12.24 7.72a15.06 15.06 0 010 25.48l-12.24 7.72a8 8 0 00-3.73 6.76V139h56v-11.78a8 8 0 00-3.73-6.76l-12.24-7.72a15.06 15.06 0 010-25.48l12.24-7.72a8 8 0 003.73-6.76V61H72z"
        fill="#fff"
      />
    </svg>
  );
};
