import { useCallback, useEffect, useState } from 'react';

type ValueType = boolean | number | string;

export function useMedia(
  queries: string[],
  values: ValueType[],
  defaultValue: ValueType,
): ValueType {
  const mediaQueryLists = queries.map((query) => window.matchMedia(query));
  const getValue = useCallback(() => {
    const index = mediaQueryLists.findIndex((mql) => mql.matches);

    if (values[index] === undefined) {
      return defaultValue;
    }

    return values[index];
  }, [defaultValue, mediaQueryLists, values]);

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);

    // В macOS и iOs Телеграмм отрывает webview на движке Safari, из-за чего не работают
    // методы addEventListener и removeEventListener. Приходится использовать deprecated
    // addListener и removeListener.
    mediaQueryLists.forEach((mql) => mql.addListener(handler));

    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, [mediaQueryLists, getValue]);

  return value;
}
