import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { APMPage } from '~/containers/APMPage';
import { SecureCodePage } from '~/containers/SecureCodePage';

import { NotFoundPage } from '~/containers/NotFoundPage';
import { RegistrationContainer } from '~/containers/RegistrationContainer';
import { SessionStatusPage } from '~/containers/SessionStatusPage';
import { SettingsContainer } from '~/containers/SettingsContainer';
import { SumsubContainer } from '~/containers/SumsubContainer';

import { DEFAULT_LOCALE, locales } from '~/i18n/locales';

import { TranslationsContext } from './TranslationsContext';

export const LocalizedRoutes: React.FC = () => {
  const {
    params: { langCode },
    url,
  } = useRouteMatch<{ langCode: string }>();
  const [translations, setTranslations] = useState(null);

  useEffect(() => {
    async function init() {
      const locale = locales[langCode] ?? locales[DEFAULT_LOCALE];

      const [loadedUITranslations, loadedCountriesTranslations] =
        await Promise.all([locale.loadUI(), locale.loadCountries()]);

      const loadedTranslations = {
        ...loadedUITranslations,
        ...loadedCountriesTranslations,
      };

      setTranslations(loadedTranslations);
    }

    init();
  }, [langCode]);

  if (!translations) {
    return null;
  }

  return (
    <TranslationsContext.Provider
      value={{ i18n: translations, lang: langCode }}
    >
      <Switch>
        <Route exact path={`${url}/secure-code/:hashPayment/:signPayment`}>
          <SecureCodePage />
        </Route>

        <Route
          exact
          path={`${url}/apm/payment/:hashTokenization/:signTokenization/:hashPayment/:signPayment`}
        >
          <APMPage mode="payment" />
        </Route>

        <Route
          exact
          path={`${url}/apm/tokenize/:hashTokenization/:signTokenization`}
        >
          <APMPage mode="tokenize" />
        </Route>

        <Route exact path={`${url}/registration/:authorId/:sign`}>
          <RegistrationContainer />
        </Route>

        <Route
          path={`${url}/session_status/:botId/:botAccount/:sessionId/:hash`}
        >
          <SessionStatusPage />
        </Route>

        <Route exact path={`${url}/settings/:authorId/:sign`}>
          <SettingsContainer />
        </Route>

        <Route exact path={`${url}/id/:authorId`}>
          <SumsubContainer />
        </Route>

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </TranslationsContext.Provider>
  );
};
