import * as React from 'react';
import { useParams } from 'react-router';

import { MUIButton } from '~/components/MUI/MUIButton';
import { MUIStatus, Statuses } from '~/components/MUI/MUIStatus';
import { PageRoot } from '~/components/PageRoot';
import { usePlatform } from '~/components/Platform';
import { StatusContainer } from '~/components/StatusContainer';

import { Status } from './constants';
import { useStyles } from './styles';
import type { BotAccount, BotId, Hash, SessionId } from './types';
import { useSessionStatus } from './useSessionStatus';

export const SessionStatusPage: React.FC = () => {
  const { botAccount, botId, hash, sessionId } = useParams<{
    botAccount: BotAccount;
    botId: BotId;
    hash: Hash;
    sessionId: SessionId;
  }>();

  const platform = usePlatform();

  const classes = useStyles();

  const { error, session } = useSessionStatus({
    botAccount,
    botId,
    hash,
    sessionId,
  });

  const stage = React.useMemo(() => {
    if (error) {
      return (
        <MUIStatus
          status={Statuses.Error}
          statusButton={
            platform.isAndroid ? (
              <div className={classes.returnButtonContainer}>
                <MUIButton href={session.returnUrl}>Back to Bot</MUIButton>
              </div>
            ) : null
          }
          statusDescription={error}
          statusTitle="Something went wrong"
        />
      );
    }

    if (session === null || session.status === Status.InProgress) {
      return (
        <MUIStatus
          status={Statuses.Pending}
          statusDescription="Please don't reload this page"
          statusTitle="Transaction in progress..."
        />
      );
    }

    if (
      session.status === Status.Cancelled ||
      session.status === Status.Error
    ) {
      return (
        <MUIStatus
          status={Statuses.Error}
          statusButton={
            platform.isAndroid ? (
              <div className={classes.returnButtonContainer}>
                <MUIButton href={session.returnUrl}>Back to Bot</MUIButton>
              </div>
            ) : null
          }
          statusDescription={session.error}
          statusTitle="Something went wrong"
        />
      );
    }

    if (session.status === Status.Accepted) {
      window.location.href = session.returnUrl;
    }

    return null;
  }, [classes.returnButtonContainer, error, platform.isAndroid, session]);

  return (
    <PageRoot>
      <StatusContainer>{stage}</StatusContainer>
    </PageRoot>
  );
};
