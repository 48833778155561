import React, { memo, useMemo } from 'react';
import { createMuiTheme, Theme, ThemeProvider, Zoom } from '@material-ui/core';

import { PlatformContextType, usePlatform } from '~/components/Platform';
import { Colors } from '~/constants';
import { useMedia } from '~/hooks/useMedia';

export type Mode = 'dark' | 'white';

export interface CustomTheme extends Theme {
  mode: Mode;
  platform: PlatformContextType;
}

export const MUITheme: React.FC = memo(({ children }) => {
  const platform = usePlatform();
  const isDarkMode = useMedia(['(prefers-color-scheme: dark)'], [true], false);
  const mode = isDarkMode ? 'dark' : 'white';

  const theme = useMemo(
    () =>
      createMuiTheme(
        {
          overrides: {
            MuiCssBaseline: {
              '@global': {
                html: {
                  minHeight: '100%',
                  margin: 0,
                  padding: 0,
                  backgroundColor: {
                    dark: {
                      android: '#1b2734',
                      iOS: '#1b2d3c',
                    }[platform.name],
                    white: '#fff',
                  }[mode],
                },

                body: {
                  margin: 0,
                  padding: 0,
                  backgroundColor: 'transparent',
                  color: {
                    dark: '#fff',
                    white: {
                      android: '#222',
                      iOS: '#000',
                    }[platform.name],
                  }[mode],
                  fontFamily: {
                    android: 'Roboto, sans-serif',
                    iOS: '"SF Pro Display", sans-serif',
                  }[platform.name],
                  fontSize: '14px',
                  fontWeight: 'normal',
                  letterSpacing: 0,
                  lineHeight: '1.36',
                },

                p: {
                  margin: '0 0 18px',
                },

                strong: {
                  fontWeight: 'bold',
                },
              },
            },
          },

          palette: {
            primary: {
              main: {
                dark: {
                  android: '#4fa8eb',
                  iOS: '#119fef',
                }[platform.name],
                white: {
                  android: Colors.AndroidBlue,
                  iOS: Colors.IOSBlue,
                }[platform.name],
              }[mode],
            },
            text: {
              secondary: {
                dark: '#7c8b99',
                white: '#999',
              }[mode],
            },
          },

          props: {
            MuiPopover: {
              TransitionComponent: Zoom,
            },
          },
        },
        {
          mode,
          platform,
        },
      ),
    [mode, platform],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
});
