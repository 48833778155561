import { ApiService, RequestResult } from '~/services/ApiService';

import type {
  BankGlobalPayoutMethod,
  OptionsGlobalPayoutMethod,
} from '~/hooks/useAvailableFieldOptionsGlobalBankAccount';
import { PayoutType } from '~/components/PayoutSettingsFields';
import type {
  BotAccount,
  BotId,
  Hash,
  Session,
  SessionId,
} from '~/containers/SessionStatusPage';

import { Options } from './types';
import { CountriesDictionary } from '~/hooks/useAvailableFieldOptionsGlobalBankAccount';

type RequestFunction<Req, Res> = (payload: Req) => Promise<RequestResult<Res>>;

export interface Routes {
  authorDetails: RequestFunction<string, unknown>;
  getAvailableBanksGlobalBankAccount: (params: {
    country: string;
    currency: string;
  }) => Promise<BankGlobalPayoutMethod[]>;
  getAvailableCurrenciesFieldOptionsGlobalBankAccount: (
    countyCodeIso3: string,
  ) => Promise<OptionsGlobalPayoutMethod>;
  getAvailablePayoutCountries: () => Promise<{ [key: string]: PayoutType[] }>;
  getChannelLanguagesList: () => Promise<
    {
      code: string;
      translations?: {
        en: string;
        native: string;
      };
    }[]
  >;
  getCountriesList: () => Promise<{ ico: string }[]>;
  getDictionaryCountries: () => Promise<CountriesDictionary>;
  getSessionStatus: (params: {
    botAccount: BotAccount;
    botId: BotId;
    hash: Hash;
    sessionId: SessionId;
  }) => Promise<Session | { description: string }>;
  loadTermsCreator: (lang: string) => Promise<string>;
  register: RequestFunction<unknown, unknown>;
  settings: RequestFunction<unknown, unknown>;
  subscribeToCountry: RequestFunction<unknown, unknown>;
  sumsubToken: RequestFunction<
    { uuid: string; ttl?: number },
    { status: string; token: string }
  >;
}

function createHeaders({ publicToken, uuid, sign }: Options) {
  const publicTokenHeader = publicToken
    ? { 'X-PUBLIC-TOKEN': publicToken }
    : {};
  const userHeader = uuid ? { 'X-USER': uuid } : {};
  const authTokenHeader = sign ? { 'X-AUTH-TOKEN': sign } : {};

  return {
    ...publicTokenHeader,
    ...userHeader,
    ...authTokenHeader,
  };
}

export function createRoutes(options: Options): Routes {
  const headers = createHeaders(options);

  return new ApiService(
    ({ get, post }) => {
      return {
        authorDetails(authorId: string) {
          return get(`/author/${authorId}/details`);
        },

        async getAvailableBanksGlobalBankAccount(params: {
          country: string;
          currency: string;
        }) {
          const { country, currency } = params;

          const { response } = await get(
            `/payout_methods/global/banks?country=${country}&currency=${currency}`,
          );

          return response;
        },

        async getAvailablePayoutCountries() {
          const {
            response: { countries },
          } = await get('/payout_methods/available_countries');

          return countries.reduce(
            (result, { availablePayoutMethods, countyCodeIso3 }) => {
              return {
                ...result,
                [countyCodeIso3]: availablePayoutMethods,
              };
            },
            {},
          );
        },

        async getAvailableCurrenciesFieldOptionsGlobalBankAccount(
          countyCodeIso3: string,
        ) {
          const { response } = await get(
            `/payout_methods/global/${countyCodeIso3}/params`,
          );

          return response;
        },

        async getChannelLanguagesList() {
          const loadLanguages = async () => {
            const {
              response: { languages },
            } = await get('/author/channel_languages');

            return languages;
          };

          const loadTranslations = async () => {
            const data = await import('~/i18n/translations/languages.json');

            return data.default;
          };

          const [languages, translations] = await Promise.all([
            loadLanguages(),
            loadTranslations(),
          ]);

          return languages.map(({ code }) => {
            return {
              code,
              translations: translations[code],
            };
          });
        },

        async getCountriesList() {
          const { default: data } = await import('../../data/countries.json');

          return data;
        },

        async getDictionaryCountries() {
          const {
            response: { countries },
          } = await get('/dictionary/countries');
          return countries;
        },

        async getSessionStatus(params: {
          botAccount: BotAccount;
          botId: BotId;
          hash: Hash;
          sessionId: SessionId;
        }) {
          const { botAccount, botId, hash, sessionId } = params;

          const { response } = await get<Session>(
            `/status/${botId}/${botAccount}/${sessionId}/${hash}`,
            {
              baseUrl: process.env.API_PAYMENT_BOT_BASE_URL,
            },
          );

          return response;
        },

        async loadTermsCreator(lang) {
          async function load() {
            switch (lang) {
              case 'en':
              default:
                return import('~/i18n/translations/terms-creator.en.md');
            }
          }

          const { default: content } = await load();

          return content;
        },

        register(data) {
          return post('/author/registration', { data });
        },

        settings(data) {
          return post('/author/settings', { data });
        },

        subscribeToCountry(data) {
          return post('/author/subscribe/country', { data });
        },

        sumsubToken(data) {
          return post('/sumsub/token', { data });
        },
      };
    },
    { headers },
  ).routes;
}
