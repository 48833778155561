import { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CustomTheme } from '~/components/MUI/MUITheme';

export const useStyles = makeStyles((theme: CustomTheme) => {
  const { mode, platform, palette } = theme;

  return createStyles({
    root: {
      minWidth: ({ fullWidth }: ButtonProps) => {
        if (fullWidth) {
          return null;
        }

        if (platform.isIOS) {
          return '292px';
        }

        return '197px';
      },
      padding: '15px 18px',
      borderRadius: platform.isIOS ? 10 : 6,
      color: '#fff',
      fontFamily: 'inherit',
      fontSize: platform.isIOS ? '18px' : '15px',
      fontWeight: platform.isIOS ? 600 : 500,
      letterSpacing: 0,
      lineHeight: '18px',
      textTransform: 'none',
      boxShadow: 'none',

      '&:hover': {
        boxShadow: 'none',
      },
    },

    contained: {
      '&$disabled': {
        backgroundColor: palette.primary.main,
        boxShadow: 'none',
        color: {
          android: '#fff',
          iOS: 'rgba(255, 255, 255, 0.6)',
        }[platform.name],
        cursor: 'not-allowed',
      },
    },

    disabled: {},

    textPrimary: {
      color: {
        dark: {
          android: '#77c4fe',
          iOS: '#119fef',
        }[platform.name],
        white: {
          android: '#4197d9',
          iOS: '#007aff',
        }[platform.name],
      }[mode],
    },
  });
});
