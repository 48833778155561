import React, { memo } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

import { useStyles } from './styles';

type MUIButtonProps = ButtonProps;

export const MUIButton: React.FC<MUIButtonProps> = memo((props) => {
  const { children, variant = 'contained', ...restProps } = props;
  const classes = useStyles(props);

  return (
    <Button {...restProps} classes={classes} color="primary" variant={variant}>
      {children}
    </Button>
  );
});
