import * as React from 'react';

export const loadable = <Props,>(
  importFunction: () => Promise<{ default: React.ComponentType<Props> }>,
  options: { fallback?: React.ReactNode } = {},
): React.FC<React.PropsWithRef<React.PropsWithChildren<Props>>> => {
  const { fallback = null } = options;
  const LazyComponent = React.lazy(importFunction);

  return (props) => {
    return (
      <React.Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </React.Suspense>
    );
  };
};
