import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CustomTheme } from '~/components/MUI/MUITheme';

export const useStyles = makeStyles(({ mode, platform }: CustomTheme) =>
  createStyles({
    root: {
      color: {
        dark: {
          android: '#7c8b99',
          iOS: '#fff',
        }[platform.name],
        white: {
          android: '#707070',
          iOS: '#000',
        }[platform.name],
      }[mode],
    },
  }),
);
